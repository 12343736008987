.tuttooltip{
    background: linear-gradient(white,rgb(194, 192, 192)) !important;
    filter: drop-shadow(2px 4px 8px #585858) !important;
    font-size: 1em !important;
    color: black !important;
    opacity: 1 !important;
    border: 0 !important;
    border-radius: 8px !important;
}

@media screen and (min-width:0px) and (max-width:339px) {
    .tuttooltip{ 
        max-width:  82%;
        min-width: 82%;
        left: 2% !important;}
}

@media screen and (min-width:340px) and (max-width:479px) {
    .tuttooltip{ 
        max-width:  82%;
        min-width: 82%;
        left: 3% !important;}
}

@media screen and (min-width:480px) and (max-width:689px) {
    .tuttooltip{ 
        max-width:  82%;
        min-width: 82%;
        left: 5% !important;}
}
 

