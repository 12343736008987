button {
    color: #000000;
    cursor: pointer;
    padding: 0px 30px;
    display: inline-block;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 1.5px;
    font-size: 1em;
    outline: none;
    position: relative;
    font-size: 14px;
    border: 3px solid grey;
    background-color: #ffffff;
    border-radius: 15px 15px 15px 15px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  button:hover,
  button.hover {
    border-color: #2980b9;
    background: green;
    color: white;
  }

  input[type=text], input[type=password] {
    width: 30%;
    height: 50px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    background-color: white;
    transition: width 0.4s ease-in-out;
  }
  #macadresse {
    background-image: url('../img/searchicon.png');
    background-size: 20px 20px;
    background-position: 20px 12px; 
    background-repeat: no-repeat;
  }
  
  input[type=text]:focus, input[type=password]:focus {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    input[type=text], input[type=password] {
        width: 95%;
        font-size: 12px;
        padding: 12px 45px 12px 45px;
        margin-top: 10%;
    }
    button {
      padding: 10px 25px;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    input[type=text],input[type=password]{
      margin-top: 5%;}
    }

    #checkbox_pw {
      display: flex;
      justify-content: center;
    }