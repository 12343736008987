.MultiField {
    width: 20%;
    height: 20%;
    margin: 16px;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    text-align: center;
    display: inline-block;
    padding: 16px;
    margin-bottom: 10%;
}

#multifieldtooltiptable{
    margin-left: auto;
    margin-right: auto;
}

.provider {
    margin: 5%;
    display: inline-block;
}

.active {
    color: black;
    font-weight: bold;
}
 
.active:hover {
    cursor: pointer;
    font-size: 120%;
}

.inactive {
    color: grey;
}

@media screen and (max-width: 767px) {
    .MultiField {
        width: 80%;
        height: 20%;
        margin: 5px;}
    }

@media screen and (min-width: 768px) and (max-width: 862px) {
    .MultiField {
        width: 28%;
        height: 20%;}

    #ueberschrift_status{
        width: 65%;
        margin-left: auto;
        margin-right: auto;
        }
    #ueberschrift_providerinfo{
        width: 68%;
        margin-left: auto;
        margin-right: auto;
        }
    #ueberschrift_detailinfos{
        width: 58%;
        margin-left: auto;
        margin-right: auto;
        }
    }

@media screen and (min-width: 863px) and (max-width: 947px) {
    .MultiField {
        width: 28%;
        height: 20%;}

    #ueberschrift_status{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        }
    #ueberschrift_providerinfo{
        width: 62%;
        margin-left: auto;
        margin-right: auto;
        }
    #ueberschrift_detailinfos{
        width: 54%;
        margin-left: auto;
        margin-right: auto;
        }
    }

@media screen and (min-width: 948px) and (max-width: 1024px) {
    .MultiField {
        width: 28%;
        height: 20%;}

    #ueberschrift_status{
        width: 52%;
        margin-left: auto;
        margin-right: auto;
        }
    #ueberschrift_providerinfo{
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        }
    #ueberschrift_detailinfos{
        width: 54%;
        margin-left: auto;
        margin-right: auto;
        }
    }

    @media screen and (min-width: 1025px) and (max-width: 1085px){
        #ueberschrift_status{
            width: 48%;
            margin-left: auto;
            margin-right: auto;
        }
        #ueberschrift_providerinfo{
            width: 52%;
            margin-left: auto;
            margin-right: auto;
        }
        #ueberschrift_detailinfos{
            width: 44%;
            margin-left: auto;
            margin-right: auto;
        }
        }
@media screen and (min-width: 1086px) and (max-width: 1200px){
    #ueberschrift_status{
        width: 45%;
        margin-left: auto;
        margin-right: auto;
    }
    #ueberschrift_providerinfo{
        width: 48%;
        margin-left: auto;
        margin-right: auto;
    }
    #ueberschrift_detailinfos{
        width: 41%;
        margin-left: auto;
        margin-right: auto;
    }
    }

    @media screen and (min-width: 1201px) and (max-width: 1249px){
        #ueberschrift_status{
            width: 42%;
            margin-left: auto;
            margin-right: auto;
        }
        #ueberschrift_providerinfo{
            width: 45%;
            margin-left: auto;
            margin-right: auto;
        }
        #ueberschrift_detailinfos{
            width: 38%;
            margin-left: auto;
            margin-right: auto;
        }
        }

        @media screen and (min-width: 1250px) and (max-width: 1370px){
            #ueberschrift_status{
                width: 40%;
                margin-left: auto;
                margin-right: auto;
            }
            #ueberschrift_providerinfo{
                width: 42%;
                margin-left: auto;
                margin-right: auto;
            }
            #ueberschrift_detailinfos{
                width: 36%;
                margin-left: auto;
                margin-right: auto;
            }
            }


        @media screen and (min-width: 1371px) and (max-width: 1500px){
            #ueberschrift_status{
                width: 36%;
                margin-left: auto;
                margin-right: auto;
            }
            #ueberschrift_providerinfo{
                width: 38%;
                margin-left: auto;
                margin-right: auto;
            }
            #ueberschrift_detailinfos{
                width: 34%;
                margin-left: auto;
                margin-right: auto;
            }
            }

    @media screen and (min-width: 1501px){
        #ueberschrift_status{
            width: 35%;
            margin-left: auto;
            margin-right: auto;
        }
        #ueberschrift_providerinfo{
            width: 36%;
            margin-left: auto;
            margin-right: auto;
        }
        #ueberschrift_detailinfos{
            width: 40%;
            margin-left: auto;
            margin-right: auto;
        }
        }
