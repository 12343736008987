input.login_password{
    display: flex;
    margin-left: auto; 
    margin-right: auto;
}

.loginheader{
    font-weight: bold;
    font-size: 1.17em;
}

.login_password_before{
    margin-top: 15%;
    text-align: center;
    padding: 10px;
    background: linear-gradient(white,rgb(194, 192, 192));
    filter: drop-shadow(2px 4px 8px #585858);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
}

input#login_password {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-image: none;
    width: 50%; 
} 

input#login_username {
    background-image: none;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 1%;
}

.wrong_password_text{
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: white;
    border: 1px solid red;
    background: red;
    width: fit-content;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.login_password_wrong{
    margin-top: 15%;
    text-align: center;
    padding: 10px;
    background: linear-gradient(white,rgb(194, 192, 192));
    filter: drop-shadow(2px 4px 8px #585858);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

 button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}  

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .login_password_before {
        width: 70%;        
    }
    input[type=text], input[type=password] {
        padding:0%
      }
}

@media screen and (max-width: 767px) {
    .login_password_before {
        width: 90%;
    }
    input[type=text], input[type=password] {
        padding: 0%;
      }
}

@media screen and (min-width: 1920px) {
    .login_password_before {
        width: 30%;
    }
}