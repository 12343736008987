.AppStyle {
  text-align: center;
  margin-bottom: 10%
}

h1{
  font-size: 1.8em;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  margin-bottom: 100px;
  min-height: 50px
}

li {
  float: right;
}

.li_impressum_datenschutz{
  float:left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
li a:hover {
  background-color: #fff;
  font-weight: bold;
  margin: 0;
}

.active-header {
  margin: 0;
  font-size: 1.4em;
}
.active-header:hover {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.logout, .impressum, .datenschutz {
  text-decoration: underline;
  cursor: pointer;
}

.logout:hover, .impressum:hover, .datenschutz:hover {
  color:#111;
}

.div_aussen{
  position: relative;
}

video{
  position: absolute;
  height: auto;
  width: 20%;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.barcodemask{
  background: transparent;
  height: 5rem;
  width: 5rem;
  border: 5px solid red;
  z-index: 2;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.qr_text{
 position: absolute;
 height: auto;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 color: red;
 z-index: 3;
 font-weight: bold;
}


.dropdown {
  float: left;
  overflow: hidden;
  min-width: 170px;
  min-height: 50px;
}

.dropdown .dropbtn {
  border: none;
  outline: none;
  color: #fff;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  text-decoration: underline;
}
.dropdown .dropbtn:hover{
  color: #000;
  font-weight: bold;
}

a:hover, .dropdown:hover .dropbtn {
  background-color: #fff;
  color: #000;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 169px;
  border: 1px solid #333;
  border-top:0;
  z-index: 1;
  top: 49px;
  color: #000;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}


@media screen and (max-width: 300px){
  .div_aussen{
    margin-top: 35%;
  }
  video{
    height: auto;
    width: 68%;
  }
  .barcodemask{
    height: 2rem;
    width: 2rem;
    border: 2px solid red;
  }
  .qr_text{
    margin-top: 14%;
    width: 65%;
    font-size: smaller;
  }
  .dropdown-content{
    top: 147px;
  }
}

@media screen and (min-width: 301px) and (max-width: 480px){
  .dropdown-content{
    top: 98px;
  }
}

@media screen and (min-width: 301px) and (max-width: 767px) and (max-height:580px){
  .AppStyle {
    margin-bottom: 40%;
  }
  .div_aussen{
    margin-top: 45%;
  }
  video{
    height: auto;
    width: 58%;
  }
  .barcodemask{
    height: 2rem;
    width: 2rem;
    border: 2px solid red;
  }
  .qr_text{
    margin-top: 14%;
    width: 60%;
    font-size: smaller;
  }
}
@media screen and (min-width: 301px) and (max-width: 767px) and (min-height: 581px){
  .AppStyle {
    margin-bottom: 40%;
  }
  .div_aussen{
    margin-top: 45%;
  }
  video{
    height: auto;
    width: 58%;
  }
  .barcodemask{
    height: 3rem;
    width: 3rem;
    border: 2px solid red;
  }
  .qr_text{
    margin-top: 12%;
    width: 55%;
    font-size: smaller;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .AppStyle {
    margin-bottom: 40%;
  }
  .div_aussen{
    margin-top: 30%;
  }
  video{
    height: auto;
    width: 40%;
  }
  .barcodemask{
    height: 4rem;
    width: 4rem;
    border: 3px solid red;
  }
  .qr_text{
    margin-top: 10%;
    width: 30%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1024px) {
  .AppStyle {
    margin-bottom: 30%;
  }
  
  .div_aussen{
    margin-top: 30%;
  }
  video{
    height: auto;
    width: 40%;
  }
  .barcodemask{
    height: 4rem;
    width: 4rem;
    border: 3px solid red;
  }
  .qr_text{
    margin-top: 10%;
    width: 35%;
    font-size: x-large;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1800px){
  .div_aussen{
    margin-top: 15%;
  }
  video{
    height: auto;
    width: 30%;
  }
  .barcodemask{
    height: 5rem;
    width: 5rem;
  }
  .qr_text{
    margin-top: 7%;
    width: 22%;
    font-size: x-large;
  }
} 

@media screen and (min-width: 1801px){
  .div_aussen{
    margin-top: 15%;
  }
  video{
    height: auto;
    width: 30%;
  }
  .barcodemask{
    height: 5rem;
    width: 5rem;
  }
  .qr_text{
    margin-top: 6%;
    width: 20%;
    font-size: x-large;
  }
}