.InfoField {
    width: 20%;
    height: 20%;
    margin: 16px;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 16px;
    text-align: center;
    display: inline-block;
}

.tooltiptrigger {
  position: relative;
  float: right;
  top: -4em;
  z-index: 100;
  font-weight: bolder;
  color: royalblue;
  font-size: large;
}

.tooltiptrigger:hover {
  cursor: pointer;
  font-size: 125%;
  font-weight: bolder;
}

.tooltiptrigger_provider {  
  z-index: 100;
  font-weight: bold;
  color: royalblue;
  font-size: large;
}

.tooltiptrigger_provider:hover {
  cursor: pointer;
  font-weight: bolder;
}

.green {
   color: green;
   font-weight: bold;
}

.red {
  color: red;
  font-weight: bold;
}

.orange {
  color: orange;
  font-weight: bold;
}

.grey {
  color: darkslategrey;
  font-weight: bold;
}

.plus {
    display:inline-block;
    width:20px;
    height:20px;
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
  background-position:center;
  background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat:no-repeat;
  }

  .minus {
    display:inline-block;
    width:20px;
    height:20px;
    background:
    linear-gradient(#000,#000);
  background-position:center;
  background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat:no-repeat;
  }

@media screen and (max-width: 767px) {
    .InfoField {
        width: 80%;
        height: 20%;
        margin: 5px;}
    }

@media screen and (min-width: 768px) and (max-width: 849px) {
  .InfoField {
      width: 40%;
      height: 20%;}
  } 
    
  @media screen and (min-width: 850px) and (max-width: 1024px) {
      .InfoField {
        width: 42%;
        height: 20%;}
    }

@media screen and (min-width: 1025px) and (max-width: 1255px) {
  .InfoField {
      width: 30%;
      height: 20%;}
  }
 
@media screen and (min-width: 1256px) and (max-width: 1369px) {
  .InfoField {
      width: 24%;
      height: 20%;}
  }

 @media screen and (min-width: 1370px) and (max-width: 1489px) {
      .InfoField {
         width: 28%;
         height: 20%;}
     }

  @media screen and (min-width: 1490px) and (max-width: 1700px) {
    .InfoField {
        width: 28%;
        height: 20%;}
  }
 


  @media screen and (min-width: 751px) and (max-width: 822px) {
    h3{
      min-height: 50px;
    }
   }
  @media screen and (min-width: 1008px) and (max-width: 1098px) {
  h3{
    min-height: 50px;
    }
  p{
    display: flex;
    justify-content: center;
    min-height: 42px;
  }
  }
  @media screen and (min-width: 1098px) and (max-width: 1174px) {
    p{
      display: flex;
      justify-content: center;
      min-height: 42px;
    }
  }   

  @media screen and (min-width: 1200px) and (max-width: 1685px) {
    h3{
      min-height: 50px;
      }
    p{
      min-height: 42px;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: 1686px){
    p{
      min-height: 42px;
      display: flex;
      justify-content: center;
      }

  }
