svg.popup-arrow{
    display: none;
}

.show_signal_information{
   border: 1px solid #eee;
   box-shadow: 0 2px 3px #ccc;
}

.multifieldtooltiptable_td{
    width: 1%;
}